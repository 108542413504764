import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useRef } from "react";
import Layout from "../components/layout";
import { BgImage } from "gbimage-bridge";
import { Helmet } from "react-helmet";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Col, Row, Container, Button } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import Leaf from "../images/leaf.webp";
import Wave from "../images/wave.svg";

const IndexPage = () => {
	const tl = useRef();
	const {
		wpHomePage: { homePageFields, seoFields },
		site,
	} = useStaticQuery(graphql`
		query {
			wpHomePage {
				homePageFields {
					eventSection {
						link
						location
						time
						title
						btnText
						date
						backgroundImage {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
							altText
						}
					}
					heroSection {
						title
						body
						btnText
						buttonLink
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					newsAndMedia {
						title
						btnText
						backgroundText
						articles {
							link
							btnText
							description
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					positiveMindset {
						backgroundText
						body
						title
						postion
						name
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					registered {
						title
						backgroundText
						partners {
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					servicesSection {
						title
						services {
							description
							title
							priceLink
							priceBtnText
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const {
		eventSection,
		heroSection,
		newsAndMedia,
		positiveMindset,
		registered,
		servicesSection,
	} = homePageFields;

	const siteUrl = site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<BgImage
							Tag="section"
							className="hero position-relative"
							id="hero-home"
							image={getImage(
								heroSection.image.localFile.childImageSharp.gatsbyImageData
							)}
						>
							<div
								style={{ opacity: 0.4 }}
								className="w-100 h-100 bg-black position-absolute start-0 top-0"
							></div>
							<Container
								style={{ zIndex: "2" }}
								className="h-100 position-relative"
							>
								<Row className="align-items-center justify-content-center text-center h-100">
									<Col xs={12} lg={8}>
										<h1
											style={{ lineHeight: "90%" }}
											className=" display-1 Alex-Brush   text-white"
											id="home-hero-headline"
										>
											{heroSection.title}
										</h1>
										<p className="text-white mt-4">{heroSection.body}</p>
										<Button
											className="mt-4 px-4 py-3"
											href={heroSection.buttonLink}
											variant="outline-white "
										>
											{heroSection.btnText}
										</Button>
									</Col>
								</Row>
							</Container>
							<img
								src={Wave}
								alt=""
								style={{
									width: "100%",
									bottom: "-4px",
								}}
								className="position-absolute  start-0"
							/>
						</BgImage>
					</section>
					<section className="py-5 py-lg-7 position-relative">
						{/* <img
							src={Leaf}
							alt=""
							style={{
								width: "200px",
								opacity: 0.2,
								top: "-5%",
							}}
							className="position-absolute d-none d-lg-block start-10"
						/> */}
						<Container>
							<Row className="justify-content-center">
								<Col lg={8} className="text-center">
									<GatsbyImage
										className=" "
										image={
											positiveMindset.image.localFile.childImageSharp
												.gatsbyImageData
										}
										alt={positiveMindset.image.altText}
										style={{ zIndex: 4, maxWidth: "130px" }}
									/>
									<div className="position-relative">
										<p
											style={{
												zIndex: -4,
												color: "#5050503D",
											}}
											className=" top-50 start-50 big-text-bg translate-middle fw-bold Alex-Brush  position-absolute"
										>
											{positiveMindset.backgroundText}
										</p>
										<h2
											style={{ zIndex: 1 }}
											className="mt-4 mb-3 Garamond-Med position-relative display-5"
										>
											{positiveMindset.title}
										</h2>
									</div>
									<p>{positiveMindset.body}</p>
									<p className="mt-4 Alex-Brush pb-0 mb-0 fs-2">
										{positiveMindset.name}
									</p>
									<p className="Garamond-Med mt-0 pt-0 fs-5">
										{positiveMindset.postion}
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row className="justify-content-center">
								<Col lg={10} className="text-center">
									<div className="position-relative">
										<p
											style={{
												zIndex: -4,
												color: "#50414A08",
											}}
											className=" top-50 start-50 big-text-bg translate-middle fw-bold Alex-Brush  position-absolute"
										>
											{registered.backgroundText}
										</p>
										<h2 className="mb-5 mb-3 Garamond-Med display-5">
											{registered.title}
										</h2>
									</div>

									<Marquee>
										{registered.partners.map((partner, i) => (
											<GatsbyImage
												className=" me-6"
												image={
													partner.image.localFile.childImageSharp
														.gatsbyImageData
												}
												style={{ zIndex: 4, maxWidth: "180px" }}
												alt={partner.image.altText}
											/>
										))}
									</Marquee>
								</Col>
							</Row>
						</Container>
					</section>
					<section id="what-we-do" className="pb-5 pb-lg-7">
						<Container>
							<Row className="justify-content-center">
								<Col lg={10} className="text-center position-relative">
									<h2 className="mb-4 mb-3 Garamond-Med display-5">
										{servicesSection.title}
									</h2>
									<Row className="gx-4 j-100 gy-5">
										{servicesSection.services.map((service, i) => (
											<Col
												style={{ minHeight: "100%" }}
												className="text-center  "
												md={6}
											>
												<div className="position-relative h-100">
													<GatsbyImage
														className=" w-100 image-height-new"
														image={
															service.image.localFile.childImageSharp
																.gatsbyImageData
														}
														alt={service.image.altText}
													/>
													<h3 className="fs-5 my-3 Garamond-Med">
														{service.title}
													</h3>
													<p style={{ fontSize: "90%" }} className="">
														{service?.description}
													</p>
													<div style={{ height: "60px" }}></div>
													<Button
														className="position-absolute start-0 bottom-0 w-100 py-3"
														href={service.priceLink}
														variant="outline-primary "
													>
														{service.priceBtnText}
													</Button>
												</div>
											</Col>
										))}
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pt-lg-5">
						<BgImage
							className=" position-relative"
							image={getImage(
								eventSection.backgroundImage.localFile.childImageSharp
									.gatsbyImageData
							)}
						>
							<div
								style={{ opacity: 0.5 }}
								className="w-100 h-100 bg-primary position-absolute start-0 top-0"
							></div>
							<Container
								style={{ zIndex: "2" }}
								className=" position-relative py-5"
							>
								<Row className="align-items-center justify-content-center  g-4 ">
									<Col lg={6}>
										<GatsbyImage
											className="w-100 "
											image={
												eventSection.image.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={eventSection.image.altText}
										/>
									</Col>
									<Col xs={12} lg={6}>
										<h2 className="mb-4 mb-3 Garamond-Med text-white ">
											{eventSection.title}
										</h2>
										<p className="text-white ">
											<span className="Garamond-Bold">EVENT DATE:</span>{" "}
											{eventSection.date}
										</p>
										<p className="text-white ">
											<span className="Garamond-Bold">EVENT TIME:</span>{" "}
											{eventSection.time}
										</p>
										<p className="text-white ">
											<span className="Garamond-Bold">EVENT LOCATION:</span>{" "}
											{eventSection.location}
										</p>
										<Button
											className="mt-4 px-4 "
											href={eventSection.link}
											variant="outline-white "
										>
											{eventSection.btnText}
										</Button>
									</Col>
								</Row>
							</Container>
						</BgImage>
					</section>
					<section className="py-5">
						<Container>
							<Row>
								<Col>
									<div className="position-relative">
										<p
											style={{
												zIndex: -4,
												color: "#50414A08",
											}}
											className=" top-50 start-0 big-text-bg translate-middle-y fw-bold Alex-Brush  position-absolute"
										>
											{newsAndMedia.backgroundText}
										</p>
										<h2 className="mb-5  Garamond-Med display-5">
											{newsAndMedia.title}
										</h2>
									</div>
								</Col>
							</Row>
							<Row className="g-4">
								{newsAndMedia.articles.map((service, i) => (
									<Col
										style={{ minHeight: "100%" }}
										className="text-center  "
										lg={6}
									>
										<Row className="align-items-center g-3">
											<Col xs={5}>
												<GatsbyImage
													className=" w-100"
													image={
														service.image.localFile.childImageSharp
															.gatsbyImageData
													}
													alt={service.image.altText}
												/>
											</Col>
											<Col xs={7}>
												<div className="text-start">
													<h3 className="fs-5 my-3 Garamond-Med">
														{service.description}
													</h3>

													<Button
														className=""
														target="_blank"
														rel="noreferrer"
														href={service.link}
														variant="outline-primary "
													>
														{service.btnText}
													</Button>
												</div>
											</Col>
										</Row>
									</Col>
								))}
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
